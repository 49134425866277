import React, { useContext, useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'

import Spinner from 'src/components/Spinner'
import LoadingEllipsis from 'src/components/LoadingEllipsis'
import StoreContext from 'src/context/StoreContext'
import theme from 'src/utils/styles/theme'
import Analytics from 'src/utils/Analytics'
import UTMParams from 'src/utils/UTMParams'
import LocalStorage from 'src/utils/LocalStorage'
import { CenteredContent } from 'src/utils/styles/global-styles'
import loadingGif from 'src/gifs/loading.gif'
import { FormattedCurrency } from 'src/utils/FormattedCurrency'
import { IN_AU, IN_SWEDEN, IN_UK, IS_FOREIGN_COUNTRY } from '../../utils/constants'
import ProductsList from '../ProductsList'
import LineItem from './LineItem'
import {
  Table,
  TableHeader,
  DesktopOnlyTableHeader,
  Checkout,
  LineItemTotal,
  CheckoutButton,
  Excluding,
  PayPalCheckoutButton,
  CheckoutButtons,
  Discount,
  Total,
  QuantityHeader,
  TableRow,
  TD,
  DisplayOnDesktop,
  DisplayOnMobile,
  HideOnSmallScreen,
  Amount,
  Container,
  HiddenOnDesktop,
  CheckoutContainer,
  Header,
  ProductListContainer,
  DiscountText,
  Subtotal,
  Error,
  ALink,
  DiscountDisclosure,
} from './styles'

import { DISCOUNTS } from './discounts'

const defaultLanguage = process.env.GATSBY_I18N_DEFAULT_LANGUAGE
const Cart = () => {
  const { t } = useTranslation('common')

  const {
    store: { checkout, loading },
    initializeRechargeCheckout,
    isUserBuyingPlus,
    applyDiscount,
    removeDiscount,
  } = useContext(StoreContext)

  const { paypalLogo, rechargeAutoApplyDiscount } = useStaticQuery(graphql`
    query CartQuery {
      paypalLogo: file(relativePath: { eq: "paypal-logo.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 75, height: 21, layout: FIXED, placeholder: NONE)
        }
      }
      rechargeAutoApplyDiscount: allContentfulRechargeAutoApplyDiscount {
        edges {
          node {
            de
            en
            en_au
            en_ca
            en_eu
            en_gb
            es
            fr
            it
            nl
            pl
            se
          }
        }
      }
    }
  `)

  const [updatingPrices, setUpdatingPrices] = useState(false)
  const [gettingCheckout, setGettingCheckout] = useState(false)

  const [availableDiscount, setAvailableDiscount] = useState(null)
  const [appliedBundleDiscount, setAppliedDiscount] = useState(null)

  const [subtotalQuantity, setSubtotalQuantity] = useState(0)

  const [checkForAvailableDiscountOnInitialization, setCheckForAvailableDiscountOnInitialization] =
    useState(false)

  const buyingPlus = isUserBuyingPlus(checkout)

  /**
   * Nested array/object destructuring with default values:
   * Basically for each level of nesting, we are giving the array/object we are
   * trying to destructure out of a default value of empty array/object to avoid null errors .
   */
  const {
    discountApplications: [
      { code: discountCode, value: { amount: discountAmount, percentage } = {} } = {},
    ] = [],
    lineItemsSubtotalPrice: { amount: lineItemTotal } = {},
  } = checkout

  const TRACKER_PARAM_TIMEOUT_MILLI = 1000

  const safeTrackerParam = (cb) => {
    let finished = false
    setTimeout(() => {
      if (!finished) cb()
    }, TRACKER_PARAM_TIMEOUT_MILLI)
    // eslint-disable-next-line no-undef
    ga((tracker) => {
      const param = tracker.get('linkerParam')
      finished = true
      cb(param)
    })
  }

  const getBaseCheckoutUrl = async () => {
    if (buyingPlus) {
      const lang = defaultLanguage.replace('-', '_')
      let autoApplyDiscountCode = rechargeAutoApplyDiscount.edges[0].node[lang]
      if (discountCode === 'GMA' || discountCode === 'gma') {
        autoApplyDiscountCode = 'GMA'
      }
      const rechargeCheckout = await initializeRechargeCheckout()
      const baseUrl = `https://checkout.rechargeapps.com/r/checkout/${rechargeCheckout.token}?myshopify_domain=${process.env.GATSBY_SHOPIFY_STORE_NAME}`
      const rechargeCheckoutUrl =
        autoApplyDiscountCode && autoApplyDiscountCode !== 'NONE'
          ? `${baseUrl}&discount=${autoApplyDiscountCode}`
          : baseUrl
      return rechargeCheckoutUrl
    }
    return checkout.webUrl
  }

  const handleCheckout = async () => {
    setGettingCheckout(true)
    Analytics.track('Initiated Checkout')
    let queryString = ''
    const utmParams = UTMParams.get()
    Object.keys(utmParams).forEach((k) => (queryString += `&${k}=${utmParams[k]}`))
    const baseUrl = await getBaseCheckoutUrl()
    safeTrackerParam((param) => {
      if (param) queryString += `&${param}`
      window.location = baseUrl + queryString
    })
    setGettingCheckout(false)
  }

  const handleRemoveDiscount = async () => {
    await removeDiscount(checkout.id)
    setAppliedDiscount(null)
  }

  const handleApplyDiscount = async (discount) => {
    if (!discount) return
    await applyDiscount(checkout.id, discount)
    setAppliedDiscount(discount)
  }

  const checkForAvailableDiscount = (updatedCheckout) => {
    const { lineItems } = updatedCheckout || checkout
    let localDiscounted10inch = 0
    let localDiscounted15inch = 0

    const discountedFrames = [
      'The Skylight Frame',
      'The Skylight Frame (Silver)',
      'The Skylight Frame (White)',
      'The Skylight Frame (Gold)',
      'The Skylight Frame (Poppy)',
      'The Skylight Calendar',
      'The Skylight Calendar with Plus Plan',
    ]

    for (let j = 0; j < lineItems.length; j++) {
      const productName = lineItems[j].title

      if (discountedFrames.includes(productName)) {
        localDiscounted10inch += lineItems[j].quantity
      }
      if (productName.includes('15')) {
        localDiscounted15inch += lineItems[j].quantity
      }
    }

    if (localDiscounted15inch > 0 && localDiscounted10inch === 0) {
      handleApplyDiscount('MOM2024_50')
    } else if (localDiscounted10inch === 1 && localDiscounted15inch === 1) {
      handleApplyDiscount('MOM2024_90')
    } else {
      handleApplyDiscount('MOM2024')
    }
  }

  const discountsArray = [{ applicableItemHandles: [] }]

  const bannerInfo = LocalStorage.getItem('banner')

  useEffect(() => {
    if (!checkForAvailableDiscountOnInitialization && checkout.lineItems.length) {
      setCheckForAvailableDiscountOnInitialization(true)
      // if (!IS_FOREIGN_COUNTRY) {
      //   checkForAvailableDiscount()
      // }
    }
  }, [checkout, checkForAvailableDiscountOnInitialization])

  useEffect(() => {
    const totalQuantity = checkout.lineItems.reduce(
      (accumulator, currentItem) => accumulator + currentItem.quantity,
      0
    )
    setSubtotalQuantity(totalQuantity)
  }, [checkout.lineItems])

  if (loading) {
    return (
      <CenteredContent>
        <img src={loadingGif} alt="Loading your cart..." />
      </CenteredContent>
    )
  }
  if (checkout.lineItems.length === 0) {
    return (
      <Container>
        <Header>
          <Trans>You have no items in your cart. Check out our products!</Trans>
        </Header>
        <ProductListContainer>
          <ProductsList />
        </ProductListContainer>
      </Container>
    )
  }
  // const isDiscountApplied = Object.values(DISCOUNTS).includes(discountCode) && discountAmount > 0

  const items = checkout.lineItems.map((item) => (
    <LineItem
      key={item.id.toString()}
      item={item}
      discountsArray={discountsArray}
      updatingPrices={updatingPrices}
      setUpdatingPrices={setUpdatingPrices}
      // availableDiscount={availableDiscount}
      // setAvailableDiscount={setAvailableDiscount}
      // appliedBundleDiscount={appliedBundleDiscount}
      // setAppliedBundleDiscount={setAppliedBundleDiscount}
      bannerInfo={bannerInfo}
      strikethrough={false}
      checkForAvailableDiscount={checkForAvailableDiscount}
      subtotalQuantity={subtotalQuantity}
    />
  ))

  return (
    <>
      <CheckoutContainer>
        <Table narrow={IN_SWEDEN}>
          <thead>
            <tr>
              <TableHeader textAlign="left">
                <Trans>Product</Trans>
              </TableHeader>
              <DesktopOnlyTableHeader width="118px">
                <Trans>Price</Trans>
              </DesktopOnlyTableHeader>
              <QuantityHeader width="118px">
                <Trans>Quantity</Trans>
              </QuantityHeader>
              <DesktopOnlyTableHeader width="118px">
                <Trans>Subtotal</Trans>
              </DesktopOnlyTableHeader>
              <HiddenOnDesktop>
                <TableHeader width={IN_SWEDEN ? '90px' : '60px'}>
                  <Trans>Price</Trans>
                </TableHeader>
              </HiddenOnDesktop>
            </tr>
          </thead>
          <tbody>{items}</tbody>
        </Table>
        <DisplayOnDesktop>
          <Table>
            <thead>
              <TableHeader textAlign="left" />
              {(discountCode && parseFloat(discountAmount) > 0) || percentage ? (
                <>
                  <TableHeader width="118px">
                    <Trans>Subtotal</Trans>
                  </TableHeader>
                  <QuantityHeader width="118px">
                    <Trans>Discount</Trans>
                  </QuantityHeader>
                </>
              ) : (
                <>
                  <TableHeader />
                  <TableHeader />
                </>
              )}
              <DesktopOnlyTableHeader width="118px">
                <Trans>Grand Total</Trans>
              </DesktopOnlyTableHeader>
            </thead>
            <tbody>
              <TableRow>
                <TD />
                {(discountCode && parseFloat(discountAmount) > 0) || percentage ? (
                  <>
                    <TD>
                      <LineItemTotal>
                        {updatingPrices ? (
                          <LoadingEllipsis />
                        ) : (
                          <s>{FormattedCurrency(lineItemTotal)}</s>
                        )}
                      </LineItemTotal>
                    </TD>
                    <TD>
                      {(parseFloat(discountAmount) > 0 || percentage) && (
                        <Discount>
                          <>
                            {updatingPrices ? (
                              <LoadingEllipsis />
                            ) : (
                              <Amount color="#2178af">{` ${FormattedCurrency(
                                lineItemTotal - checkout.totalPrice?.amount
                              )}`}</Amount>
                            )}
                          </>
                        </Discount>
                      )}
                    </TD>
                  </>
                ) : (
                  <>
                    <TD />
                    <TD />
                  </>
                )}
                <TD>
                  <Total>
                    {updatingPrices ? (
                      <Spinner />
                    ) : (
                      <Amount color="#f66951">
                        {FormattedCurrency(checkout.totalPrice?.amount)}
                      </Amount>
                    )}
                  </Total>
                </TD>
              </TableRow>
            </tbody>
          </Table>
        </DisplayOnDesktop>
        <Checkout>
          <DisplayOnMobile>
            {((discountCode && parseFloat(discountAmount) > 0) || percentage) && (
              <>
                <LineItemTotal>
                  <span>
                    <Trans>Subtotal</Trans>
                  </span>
                  {updatingPrices ? (
                    <LoadingEllipsis />
                  ) : (
                    <Subtotal strikethrough={false}>{FormattedCurrency(lineItemTotal)}</Subtotal>
                  )}
                </LineItemTotal>
                {(parseFloat(discountAmount) > 0 || percentage) && (
                  <Discount>
                    <>
                      <span>
                        <Trans>Discount</Trans>{' '}
                      </span>
                      {updatingPrices ? (
                        <LoadingEllipsis />
                      ) : (
                        <DiscountText>
                          {FormattedCurrency(lineItemTotal - checkout.totalPrice?.amount)}
                        </DiscountText>
                      )}
                    </>
                  </Discount>
                )}
              </>
            )}
            <Total>
              <span>
                <Trans>Grand Total</Trans>
              </span>
              {updatingPrices ? (
                <Spinner />
              ) : (
                <span>{FormattedCurrency(checkout.totalPrice?.amount)}</span>
              )}
            </Total>
          </DisplayOnMobile>
          {/* {!IS_FOREIGN_COUNTRY && (
            <DiscountDisclosure>
              Use code <span>MOM2024</span> at checkout to receive discounted price
            </DiscountDisclosure>
          )} */}
          {!IN_UK && (
            <Excluding>
              <Trans>Excluding Tax and Shipping</Trans>
            </Excluding>
          )}
          {subtotalQuantity > 24 && (
            <Error>
              <Trans>For bulk orders (25+ Skylight products), please contact</Trans>{' '}
              <ALink href="mailto:help@skylightframe.com"> help@skylightframe.com </ALink>{' '}
              <Trans>or adjust your cart to under 25 items.</Trans>
            </Error>
          )}
          <CheckoutButtons marginTop={IN_UK}>
            {!buyingPlus && !IN_AU && (
              <PayPalCheckoutButton
                color="#ffc439"
                type="button"
                onClick={handleCheckout}
                disabled={checkout.lineItems.length === 0 || subtotalQuantity > 24}
              >
                <GatsbyImage image={paypalLogo.childImageSharp.gatsbyImageData} alt="PayPal Logo" />
              </PayPalCheckoutButton>
            )}
            <CheckoutButton
              color={theme.coral}
              type="button"
              onClick={handleCheckout}
              disabled={checkout.lineItems.length === 0 || subtotalQuantity > 24}
            >
              {gettingCheckout ? <Spinner color="white" size={26} /> : t('Check out')}
            </CheckoutButton>
          </CheckoutButtons>
        </Checkout>
      </CheckoutContainer>
    </>
  )
}

export default Cart
